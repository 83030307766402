import React from 'react';

import { getPrice } from '../../../utils/utils';

const Totals = ({ cart, site }) => {
  const duty = parseFloat(cart?.cost.totalDutyAmount?.amount) || 0;
  const tax = parseFloat(cart?.cost.totalTaxAmount?.amount) || 0;

  // prettier-ignore
  const linesAmount = cart?.lines.edges.reduce((acc, { node }) => {
    const compareAt = parseFloat(node.cost.compareAtAmountPerQuantity.amount);
    const price = parseFloat(node.cost.amountPerQuantity.amount);
    const c = compareAt !== 0 && compareAt > price ? compareAt : price;
    return acc + (c * node.quantity)
  }, 0) || 0;

  const subTotal = Math.round(linesAmount * 100) / 100;

  const taxSubtracted = parseFloat(cart?.cost.totalAmount.amount) - tax;
  const total = Math.round(taxSubtracted * 100) / 100;

  const isDiscounted = Math.abs(subTotal - total);

  return (
    <div className="cart__totals">
      <div className="totals__total">
        <span>Subtotal</span>
        <span>{getPrice(site, subTotal)}</span>
      </div>

      <div className="totals__total">
        <span>Discounts</span>
        <span>
          {isDiscounted ? '-' : ''}
          {getPrice(site, isDiscounted)}
        </span>
      </div>

      <div className="totals__total">
        <span>
          <strong>Estimated Total</strong>
        </span>
        {isDiscounted ? (
          <span className="price">
            <span className="price price--old">{getPrice(site, subTotal)}</span>
            <span className="price price--new">{getPrice(site, total)}</span>
          </span>
        ) : (
          <span className="price">{getPrice(site, total)}</span>
        )}
      </div>
    </div>
  );
};

export default Totals;
