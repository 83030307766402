import React, { useContext, useEffect, useState } from 'react';

import Link from '../../Link';
import Logos from '../Logos';
import Product from '../../../models/Product';
import { Trash } from '../Icons';
import { CartContext } from './Cart';
import { getPrice } from '../../../utils/utils';

const LineItem = ({ lineItem }) => {
  const { site, updateLineItem } = useContext(CartContext);
  const [quantity, setQuantity] = useState(lineItem.quantity);
  const { cost, merchandise } = lineItem;

  useEffect(() => setQuantity(lineItem.quantity), [lineItem.quantity]);

  const onChange = (e) => setQuantity(parseInt(e.target.value));
  const onBlur = () => updateLineItem(lineItem.id, quantity);
  const onDecrement = () => updateLineItem(lineItem.id, lineItem.quantity - 1);
  const onIncrement = () => updateLineItem(lineItem.id, lineItem.quantity + 1);
  const onRemove = () => updateLineItem(lineItem.id, 0);

  const sub =
    cost.compareAtAmountPerQuantity?.amount > 0
      ? cost.compareAtAmountPerQuantity.amount
      : cost.amountPerQuantity.amount;
  const oldTotal = sub * quantity;
  const newTotal = cost.totalAmount.amount;

  const newDiscountedSub = newTotal / quantity;

  const brandUID = merchandise.product.vendor.toLowerCase().replace(' ', '-');
  const product = {
    ...new Product(
      {
        path: merchandise.product.handle,
      },
      site.siteUID,
      site
    ),
    brandUID,
  };

  const quantityContainer = (
    <div className="quantity-container">
      <button className="quantity-button" onClick={onDecrement}>
        -
      </button>
      <input
        className="quantity"
        type="number"
        onChange={onChange}
        onBlur={onBlur}
        value={quantity}
      />
      <button className="quantity-button" onClick={onIncrement}>
        +
      </button>
    </div>
  );

  const price = (oldPrice, newPrice) =>
    oldPrice > newPrice ? (
      <>
        <span className="price price--old">{getPrice(site, oldPrice)}</span>
        <span className="price price--new">{getPrice(site, newPrice)}</span>
      </>
    ) : (
      <span className="price">{getPrice(site, newPrice)}</span>
    );

  return (
    <tr className="cart__line-item">
      <td className="line-item__column line-item__image">
        <div className="image-container">
          {merchandise.image?.src ? (
            <img src={merchandise.image?.src} />
          ) : (
            Logos['mustad-fishing'].small
          )}
        </div>
        {quantityContainer}
      </td>

      <td className="line-item__column line-item__product">
        <div className="product-container">
          <Link to={product} site={site}>
            <span>{merchandise.product.title}</span>
            <span>{merchandise.title}</span>
          </Link>
          <div className="price-container">{price(sub, newDiscountedSub)}</div>
        </div>
      </td>

      <td className="line-item__column line-item__price price--unit">
        {price(sub, newDiscountedSub)}
      </td>

      <td className="line-item__column line-item__quantity">
        {quantityContainer}
      </td>

      <td className="line-item__column line-item__price price--subtotal">
        {price(oldTotal, newTotal)}
      </td>

      <td className="line-item__column line-item__remove">
        <button className="remove-button" onClick={onRemove}>
          {Trash}
        </button>
      </td>
    </tr>
  );
};

export default LineItem;
